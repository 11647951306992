<template>
  <div class="landing">
    <v-container class="custom-container">
      <v-card
        rounded="sm"
        class="register"
      >
        <img
          :src="require('@/assets/images/Danke_tyresnparts_startseite.png')"
          alt="Danke_tyresnparts_startseite | TyresNParts"
          style="width:100%;object-fit:contain;"
        >

        <div class="text__wrapper">
          <div class="support">
            <h5>{{ $t('langkey.get-support') }}</h5>
            <a
              :href="`mailto:${$t('langkey.mail-support')}`"
              class="mail-support"
            >
              {{ $t('langkey.mail-support') }}
            </a><br>
            <a
              :href="`tel:${$t('langkey.call-support')}`"
              class="call-support"
            >{{ $t('langkey.call-support') }}</a>
          </div>
          <h5>{{ $t('langkey.dont-have-account') }}</h5>
          <p v-html="$t('langkey.register-intro-landing')" />
        </div>
      </v-card>

      <!-- <div class="our-partners">
        <div class="title__wrapper">
          <h5>{{ $t('langkey.our-partners') }}</h5>
        </div>
        <div class="agile-wrapper">
          <agile
            ref="carousel"
            :options="sliderOptions"
          >
            <div
              v-for="(item, key) in bottomCovers"
              :key="key"
              class="slide"
            >
              <v-card
                rounded="sm"
                class="card__item rounded"
              >
                <img
                  :src="require(`@/assets/images/${item.image}`)"
                  class="img"
                  @click="onClickCover(item)"
                >
              </v-card>
            </div>
            <template slot="prevButton">
              <icon-arrow-circle />
            </template>
            <template slot="nextButton">
              <icon-arrow-circle />
            </template>
          </agile>
          <v-card
            rounded="sm"
            class="card__item-static"
          >
            <img
              :src="require('@/assets/images/Banner_log-in.webp')"
              class="img"
            >
          </v-card>
        </div>
      </div> -->
    </v-container>
    <base-dialog
      ref="simpleDialog"
    >
      <img
        class="img__term-of-service"
        :src="require(`@/assets/images/TNP_Teilnahmebedingungen_TNP-Wochen.png`)"
      >
    </base-dialog>
  </div>
</template>

<style lang="scss">
@import '@/styles/pages/landing.scss';
</style>

<script>
// import IconArrowCircle from '@/components/icons/IconArrowCircle';
// import IconRegister from '@/components/icons/IconRegister';

export default {
  components: {
    // IconArrowCircle,
    // IconRegister,
  },
  data() {
    return {
      sliderOptions: {
        slidesToShow: 2,
      },
      search: '',
      showSearchBar: false,
      bottomCovers: [
        { image: 'Lokale_Belieferung_Banner_LoginSeite.webp' },
        { image: 'Lkw_Banner_LoginSeite.webp' },
      ],
      showDialog: false,
    };
  },
  computed: {
    registerVideo() {
      return require('@/assets/videos/220624_Website_Video_V2_small.mp4');
    },
    registerVideoWebM() {
      return require('@/assets/videos/220624_Website_Video_V2_small.webm');
    },
  },
  methods: {
    toRegistration() {
      this.$router.push(
        this.$i18nRoute({
          name: 'register',
        }),
      );
    },
    onSearch() {
      if (this.search === '') {
        this.showSearchBar = !this.showSearchBar;
      }
    },
    hideSearchBar() {
      if (this.search === '') {
        this.showSearchBar = false;
      }
    },
    clearSearch() {
      this.search = '';
    },
    onClickCover(item) {
      if (item.to) {
        this.$router.push(this.$i18nRoute({
          name: item.to,
        }));
      } else if (item.hasModalContent) {
        this.onOpenDialog(item.hasModalContent);
      }
    },
    onOpenDialog(hasModalContent) {
      if(hasModalContent)
        this.$refs.simpleDialog.show();
    },
  },
};
</script>